<template>
  <div class="wharf_contianer">
    <!-- 搜区 -->
    <el-input placeholder="请输入船舶或助航设施类型名称" prefix-icon="el-icon-search" v-model="searchValue" class="search" clearable>
    </el-input>
    <div class="searchSuggest" v-show="searchValue">
      <div class="suggestList" v-for="(item, index) in suggestions" :key="index">
        <template>
          <div class="suggestItem" :class="{ active: item.name == searchSelectedName }"
            @click="targetBoatFly(item, index)">
            <span :title="item.name">{{ item.name }}</span>
          </div>
        </template>
      </div>
    </div>
    <!-- 左侧栏内容区 -->
    <aside class="aside_left">
      <div class="aside_btn">
        <div v-for="item in asideBtnAry" :key="item.id" @click="changeBtnActive(item.id)"
          :class="{ btn_active: item.id === btnActive || (item.id === 2 && btnFlag2) }">
          <img :src="item.imgUrl" style="width: 16px" />
          {{ item.name }}
        </div>
      </div>
    </aside>
    <!-- 右侧栏内容区 -->
    <!-- <aside class="aside_right">
      <div class="right_first">
        <div class="title">
          <div>
            <img src="../../assets/images/overview/2.png" alt />
            <span>船舶作业情况</span>
          </div>
          <div>
            <img src="../../assets/images/overview/title.png" alt />
          </div>
        </div>
        <div class="first_content">
          <div style="width: 330px; height: 200px">
            <basic-chart :styles-data="leftFirstChartStyles" :series-data="leftFirstChartSeries" />
          </div>
        </div>
      </div>
      <div class="right_second">
        <div class="title">
          <div>
            <img src="../../assets/images/overview/2.png" alt />
            <span>船舶状态统计</span>
          </div>
          <div>
            <img src="../../assets/images/overview/title.png" alt />
          </div>
        </div>

        <div class="second_content">
          <div style="width: 330px; height: 200px">
            <basic-chart :styles-data="leftSecondChartStyles" :series-data="leftSecondChartSeries" />
          </div>
        </div>
      </div>
    </aside> -->
    <!-- 页面气泡 -->
    <div v-if="popWharfData.length">
      <div v-for="(item, index) in popWharfData" :key="index">
        <!-- 不显示10-16号吊机，未来扩展 -->
        <pop-wharf ref="popWharfRef" :popData="item" :craneData="item.isCrane ? craneData : null "
          v-show="
          ((item.sign == 2 && btnFlag2 && btnActive == 0) || btnActive === item.sign) 
          && isShowWharfPop
          && (!item.hide)
          ">
        </pop-wharf>
      </div>
    </div>
    <!-- 船只气泡 -->
    <pop-ship></pop-ship>
    <pop-forecast></pop-forecast>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
import * as echarts from 'echarts'
// import basicChart from '@/components/echarts/basic-chart.vue' //目前无真实数据，应客户要求先隐藏
import popWharf from '../map/popMarker/pop-wharf.vue'
import popShip from '../map/popMarker/pop-ship.vue'
import popForecast from '../map/popMarker/pop-forecast.vue'
export default {
  // 组件名称
  name: 'Wharf',
  components: { popWharf, popShip, popForecast },
  data () {
    return {
      // 图表数据
      leftFirstChartStyles: {
        grid: {
          left: '1%',
          right: '2%',
          top: '15%',
          bottom: '1%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [1, 5, 9, 13, 17, 21, 25, 29],
          axisLabel: {
            color: '#ffffff'
          }
        },
        yAxis: {
          type: 'value',
          name: '日作业次数/次',
          nameTextStyle: {
            color: '#ffffff',
            fontSize: 10
          },
          min: 0,
          max: 200,
          interval: 50,
          axisLabel: {
            color: '#ffffff'
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#29506C',
              opacity: 0.5
            }
          }
        }
      },
      leftFirstChartSeries: [
        {
          data: [100, 120, 70, 190, 90, 160, 100, 110],
          type: 'line',
          smooth: false,
          lineStyle: {
            color: '#00A1F4',
            width: 1
          },
          areaStyle: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#00A1F4' },
              { offset: 1, color: 'transparent' }
            ])
          }
        }
      ],
      leftSecondChartStyles: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '0%',
          right: '0%',
          top: '15%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['巡逻船', '拖轮船', '集装箱船', '商业散货船', '自有散货船'],
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              color: '#fff',
              fontSize: 9
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '工作总数/只',
            nameTextStyle: {
              color: '#ffffff',
              // align: 'right'
              fontSize: 10
            },
            min: 0,
            max: 700,
            interval: 100,
            axisLabel: {
              color: '#ffffff'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#29506C',
                opacity: 0.5
              }
            }
          }
        ]
      },
      leftSecondChartSeries: [
        {
          name: '在册总数',
          type: 'bar',
          stack: 'total',
          data: [600, 487, 314, 245, 180],
          barWidth: 18,
          itemStyle: {
            color: '#5AC7FF'
          }
        },
        {
          name: '工作总数',
          type: 'bar',
          stack: 'total',
          data: [550, 300, 300, 220, 160],
          barWidth: 20,
          itemStyle: {
            color: '#00A6F3'
          }
        }
      ],
      // 码头设备弹窗数据
      popWharfData: [],
      // 吊机属性数据
      craneData: {},
      asideBtnAry: [
        { id: 1, name: '监控设备', imgUrl: require('../../assets/images/wharf/监控.png') },
        { id: 2, name: '码头泊位', imgUrl: require('../../assets/images/wharf/泊位.png') },
        { id: 3, name: '码头设施', imgUrl: require('../../assets/images/wharf/设施.png') }
      ],
      // 气泡显隐控制
      btnActive: 0,
      btnFlag2: false,//单独配置码头泊位按钮显隐
      isShowWharfPop: false, // 由于流程变更，这里追加显隐控制项
      // 气泡是否添加
      isPopMarkerAdd: false,
      // 搜索内容
      searchValue: null,
      searchDebounceLock: false,
      searchSelectedName: null,
      // 搜索建议
      suggestions: []
    }
  },
  watch: {
    searchValue: {
      handler: function (value) {
        let self = this
        this.suggestions.splice(0, this.suggestions.length)
        if (!value) {
          return
        }
        if (self.searchDebounceLock) return
        this.debounce(function (val) {
          self.searchDebounceLock = false
          mapApp.wharfMapActions.searchModels(val).then((result) => {
            // console.log(result)
            for (let i = 0; i < result.length; i++) {
              if (result[i].alias == 'es3dmarker') {
                // 代码添加模型
                let coord = result[i].mapCoord
                // if(result[i].isMoving && result[i].moveingCoods){ // 判断是否是移动的船
                //   coord = result[i].moveingCoods;
                // }else{
                //   coord = result[i].mapCoord;
                // }
                self.suggestions.push({
                  ID: result[i].ID,
                  name: result[i].name,
                  x: coord.x,
                  y: coord.y,
                  isMoving: result[i].isMoving
                })
              }
            }
          })
        }, 1000)(value)
        self.searchDebounceLock = true
      },
      immediate: true
    }
  },
  created () {
    // 在mapapp上挂载当前组件
    let that = this
    mapApp.wharfVueComponent = that
    this.getWharfJSON()
    this.btnFlag2 = mapApp.homeVueComponent.btnShowBerth
    // 判断地图交互模块是否已初始化完成，否则等待地图加载完成后执行
    if (mapApp.wharfMapActions) {
      mapApp.wharfMapActions.resetView()
    } else {
      mapApp.addEventListener('mapAppInitComplete', function () {
        mapApp.wharfMapActions.resetView()
        // 回填部分缓存内容
        mapApp.backfillDataDevicePopData();
      })
    }
  },
  mounted () {
    setTimeout(() => {

    }, 500);
  },
  beforeDestroy () {
    // this.clearMapEffect()
  },
  methods: {
    async getWharfJSON () {
      try {
        const { data } = await this.$http.get('/json/wharf.json')
        const { data : craneData } = await this.$http.get('/json/crane.json')

        // 绑定吊机属性数据
        this.craneData = craneData.craneProps

        // 这里的逻辑是否可以优化？
        this.popWharfData = data.popWharfData.map((item,index)=>{
          // 为摄像机数据添加url,id,poster
          if(item.sign===1){
            // 从项目名称中获取摄像头编号
            let id = parseInt(item.name.substr(4))
            item.url =  `http://39.105.44.210:9000/live${id}/index.m3u8`
            item.poster =  require(`@/assets/images/videoPoster/video${id}.png`)
            item.id = `pop-video${id}`
          }else if(item.sign===3){
            // 将吊机从码头设备中区分出来
            let isCrane = item.name.substr(0,2) === "吊机"
            item.isCrane = isCrane
            // console.log(item)
          }
          return item
        })
      } catch (err) {
        console.log('获取数据失败！')
      }
    },
    debounce (fn, wait) {
      let timer
      return function () {
        let context = this
        let args = arguments
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(function () {
          fn.apply(context, args)
        }, wait)
      }
    },
    targetBoatFly (item, index) {
      this.searchSelectedName = item.name
      // this.searchValue = item.name
      console.log('fly:', item)
      if (item.isMoving) {
        for (let i = 0; i < mapApp.wharfMapActions.searchSuggestions.length; i++) {
          if (mapApp.wharfMapActions.searchSuggestions[i].name == item.name) {
            if (mapApp.wharfMapActions.searchSuggestions[i].moveingCoods)
              (item.x = mapApp.wharfMapActions.searchSuggestions[i].moveingCoods.x),
                (item.y = mapApp.wharfMapActions.searchSuggestions[i].moveingCoods.y)
            break
          }
        }
      }
      mapApp.wharfMapActions.targetBoatFly(item)
    },
    changeBtnActive (key) {

      if (!this.isPopMarkerAdd) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.addPopWharf()
          });
        })
      }
      if (this.btnActive === key) {
        this.btnActive = 0
        mapApp.wharfMapActions.wharfPageFly()
      } else if (key !== 2 && this.btnActive !== key) {
        this.btnActive = key
        // 展示监控设备
        if (key === 1) mapApp.wharfMapActions.showMonitoringPopMarker()
        // 展示码头设施
        if (key === 3) mapApp.wharfMapActions.showFacilityPopMarker()
      }

      if (mapApp.homeVueComponent.btnShowBerth && key === 2) {
        this.clearMapEffect()
        this.btnFlag2 = false
        mapApp.homeVueComponent.btnShowBerth = false
      } else if (!mapApp.homeVueComponent.btnShowBerth && key === 2) {
        // 展示码头泊位
        mapApp.wharfMapActions.showBerthPopMarker()
        this.btnFlag2 = true
        mapApp.homeVueComponent.btnShowBerth = true
      }

    },

    // 地图气泡绑定
    getPopWharfBinding () {
      // console.log(this.$refs.popWharfRef, this.popWharfData)
      for (let i = 0; i < this.$refs.popWharfRef.length; i++) {
        // 绑定气泡组件
        mapApp.wharfMapActions.wharfPopVueComponents[i] = this.$refs.popWharfRef[i]
      }
    },
    // 码头页面气泡绑定 + 气泡定位
    addPopWharf () {
      this.isPopMarkerAdd = true
      this.getPopWharfBinding()
      mapApp.wharfMapActions.showWharfPopMarker()
    },
    // 清除页面效果
    clearMapEffect () {
      mapApp.wharfMapActions.clearPolyMarkerEffect()
    }
  }
}
</script>

<style lang="scss" scoped>
.wharf_contianer {
  .title {
    position: relative;
    width: 330px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #687188;
    background-image: linear-gradient(#fff, #73abff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 18px;
    font-family: YouSheBiaoTiHei;

    .title_left {
      margin-left: -9px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 4px;
      border-bottom: 1px solid #fff;
    }
  }

  .search {
    position: absolute;
    left: 25px;
    top: 120px;
    width: 300px;
    height: 40px;
    color: #fff;
    z-index: 1;
    animation: enter_left 1s forwards;
  }

  .searchSuggest {
    position: absolute;
    left: 25px;
    top: 162px;
    height: 100px;
    // padding-bottom: 5px;
    border-radius: 5px;
    z-index: 4;
    // background: rgba(7, 30, 62, 0.5);
    overflow-y: auto;
    overflow-x: hidden;

    .suggestItem {
      box-sizing: border-box;
      // text-overflow: ellipsis;
      white-space: nowrap;
      // overflow: hidden;
      display: flex;
      background: rgba(7, 30, 62, 0.5);
      justify-content: space-between;
      color: #33ffff;
      font-size: 12px;
      // height: 16px;
      width: 290px;
      line-height: 16px;
      cursor: pointer;
      // border-radius: 10px;
      // margin-top: 1px;
      margin-right: 2px;
      padding: 2px 10px;

      // border: 1px solid #000;
      &:hover {
        background-color: #2d637abb;
      }
    }

    .active {
      background-color: #2d637abb;
    }
  }

  .aside_left {
    position: absolute;
    left: 0;
    width: 400px;
    height: 100%;
    background-image: linear-gradient(to right, rgb(22, 28, 32), rgba(22, 28, 32, 0));
    color: #fff;
    z-index: 99;
    pointer-events: none;

    .aside_btn {
      position: relative;
      top: 40%;
      // transform: translateY(-50%);
      box-sizing: border-box;
      padding-left: 25px;
      display: flex;
      flex-direction: column;
      animation: enter_left 1s;

      div {
        width: 120px;
        height: 48px;
        box-sizing: border-box;
        border: 1px solid #16429d;
        background-color: #15428a;
        border-top-left-radius: 24px;
        border-bottom-right-radius: 24px;
        margin-bottom: 20px;
        line-height: 46px;
        font-size: 18px;
        font-family: YouSheBiaoTiHei;
        text-align: center;
        cursor: pointer;
        pointer-events: auto;

        img {
          width: 18px;
          margin: 0 -4px 5px 0;
        }
      }

      .btn_active {
        background-color: #15428a;
        box-shadow: 0 0 10px 3px #4ab1ec inset;
      }
    }
  }

  .aside_right {
    position: absolute;
    right: 0;
    width: 400px;
    height: 100%;
    color: #fff;
    box-sizing: border-box;
    padding-left: 45px;
    background-image: linear-gradient(to left, rgb(3, 12, 30), rgb(3, 10, 24, 0.6) 70%, rgba(0, 0, 0, 0));
    pointer-events: none;

    .right_first {
      position: relative;
      top: 150px;
      margin-bottom: 40px;
      animation: enter_right 1s;
      pointer-events: auto;
    }

    .right_second {
      position: relative;
      opacity: 0;
      top: 150px;
      animation: enter_right 1s 0.3s forwards;
      pointer-events: auto;
    }
  }
}

.searchSuggest::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  margin-left: 5px;
  border-radius: 5px;
}

.searchSuggest::-webkit-scrollbar-thumb {
  background: #2ccfcc;
  border-radius: 5px;
  // height: 5px;
  cursor: pointer;
}

.searchSuggest::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px 2px #1d3451;
  border-radius: 5px;
  opacity: 0.8;
}

::v-deep .el-input__inner {
  background-color: transparent;
  border-color: #567dcc;
  color: #fff;
}

::v-deep .el-icon-circle-close {
  color: rgba(255, 255, 255, 0.8);

  &:hover {
    color: #33f7f3;
  }
}

::v-deep .el-icon-search {
  color: rgba(255, 255, 255, 0.8);
}
</style>
