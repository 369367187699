<template>
  <div class="pop_container" :style="{ transform: popTips.transformStyle }">
    <div v-show="popTips.visible">
      <div @click="popExtend(popData.extend)">
        <div class="pop_txt" v-show="!isPopExtendShow">{{ popData.name }}</div>
        <div class="pop_img" :style="{ backgroundColor: popTypeStyles[popData.sign].iconColor }">
          <img v-if="(popData.sign == 3 || popData.sign == 4)" :src="popTypeStyles[popData.sign].childrenUrl[popData.name] ||popTypeStyles[popData.sign].iconUrl " />
          <img v-else :src="popTypeStyles[popData.sign].iconUrl" />
        </div>
      </div>

      <div
        class="pop_line"
        :style="{
          backgroundImage: 'linear-gradient(to bottom, ' + popTypeStyles[popData.sign].iconColor + ', rgba(0, 0, 0, 0))',
          height: popData.height + 'px  '
        }"
      ></div>

      <div class="pop_extend" v-show="isPopExtendShow">
        <div class="pop_title">
          <span>{{ popData.name }}</span>
          <div class="message">
            <div v-show="popData.position">
              位置：
              <span>{{ popData.position }}</span>
            </div>
            状态：
            <span>{{ popData.status }}</span>
          </div>
          <i class="el-icon-circle-close" @click="isPopExtendShow = false"></i>
        </div>
        <div class="pop_detail">
          <!-- 监控设备 -->
          <video-player 
            v-if="popData.sign==1"
            class="video-player" 
            :playerID="popData.id" 
            :url="popData.url" 
            :poster="popData.poster"
          /> 
          <!-- 通用内容(非吊机) -->
          <div v-if="popData.start && !popData.isCrane">
            <div class="inside">
              <div v-show="popData.charge">
                责任人：
                <span>{{ popData.charge }}</span>
              </div>
              <div v-show="popData.start">
                投入使用：
                <span>{{ popData.start }}</span>
              </div>
            </div>
          </div>
          <!-- 吊机属性 -->
          <div v-if="popData.isCrane">
            <!-- 1-8号吊机带抓斗 -->
            <div class="inside" v-if="popData.withBucket">
              <div>
                设备代码:<span>{{popData.deviceNumber}}</span>
              </div>
              <div>
                设备名称:<span>{{craneData.deviceName[0]}}</span>
                制造日期:<span>{{craneData.factoryDate}}</span>
              </div>
              <div>
                额定起重量: 
                <span>
                  抓斗(煤炭){{craneData.liftingWeight[0].coal}}、
                  抓斗(铁矿石){{craneData.liftingWeight[0].iron}}、
                  吊钩{{craneData.liftingWeight[0].hook}}
                </span>
              </div>
              <div>
                起升高度:
                <span>
                  轨面以上:{{craneData.liftHeight[0].above}}、
                  轨面以下:{{craneData.liftHeight[0].below}}
                </span>
              </div>
              <div>
                起升速度:
                <span>
                  {{craneData.liftingSpeed[0].fullLoad}}、
                  {{craneData.liftingSpeed[0].noLoad}}
                </span>
              </div> 
              <div>
                起重机尾部半径:<span>{{craneData.tailRadius}}</span>
                起重机轨距:<span>{{craneData.gauge}}</span>
              </div>
              <div>
                行走速度:<span>{{craneData.walkingSpeed}}</span>
                变幅速度:<span>{{craneData.variationSpeed[0]}}</span>
                旋转速度:<span>{{craneData.rotationSpeed}}</span>
              </div>
              <div>
                上机电压:<span>{{craneData.voltage}}</span>
                装机容量:<span>{{craneData.capacity[0]}}</span>
                钢轨型号:<span>{{craneData.railType}}</span>
              </div>
            </div>
            <!-- 9号吊机不带抓斗 -->
            <div class="inside" v-else>
              <div>
                设备代码:<span>{{popData.deviceNumber}}</span>
              </div>
              <div>
                设备名称:<span>{{craneData.deviceName[1]}}</span>
                制造日期:<span>{{craneData.factoryDate}}</span>
              </div>
              <div>
                额定起重量: 
                <span>
                  吊具下{{craneData.liftingWeight[1].hang}}、
                  吊钩{{craneData.liftingWeight[1].hook}}
                </span>
              </div>
              <div>
                起升高度:
                <span>
                  轨面以上:{{craneData.liftHeight[1].above}}、
                  轨面以下:{{craneData.liftHeight[1].below}}
                </span>
              </div>
              <div>
                起升速度:
                <span>
                  {{craneData.liftingSpeed[1].fullLoad}}、
                  {{craneData.liftingSpeed[1].noLoad}}
                </span>
              </div> 
              <div>
                起重机尾部半径:<span>{{craneData.tailRadius}}</span>
                起重机轨距:<span>{{craneData.gauge}}</span>
              </div>
              <div>
                行走速度:<span>{{craneData.walkingSpeed}}</span>
                变幅速度:<span>{{craneData.variationSpeed[1]}}</span>
                旋转速度:<span>{{craneData.rotationSpeed}}</span>
              </div>
              <div>
                上机电压:<span>{{craneData.voltage}}</span>
                装机容量:<span>{{craneData.capacity[1]}}</span>
                钢轨型号:<span>{{craneData.railType}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
import VideoPlayer from '@/components/videoPlayer/VideoPlayer'

export default {
  // 组件名称
  name: 'WharfPop',
  // 局部注册的组件
  components: {
    VideoPlayer
  },
  // 组件参数 接收来自父组件的数据
  props: { 
    popData: { 
      type: Object
    },
    craneData: {
      type: Object
    }
  },
  // 组件状态值
  data() {
    return {
      publicPath: process.env.BASE_URL,
      popTips: {
        x: null,
        y: null,
        floorNum: 1,
        offsetHeight: 0,
        transformStyle: '',
        visible: false
      },

      currPopName: null,

      // 不同的气泡类型有不同的样式配置
      popTypeStyles: {
        1: {
          name: '监控',
          iconColor: "#1594FF",
          height: 100,
          iconUrl: require('../../../assets/images/wharf/监控.png')
        },
        2: {
          name: '泊位',
          // iconColor: "#00E4FF",
          iconColor: "#1594FF",
          height: 100,
          iconUrl: require('../../../assets/images/wharf/泊位.png')
        },
        3: {
          name: '设施',
          // iconColor: "#F3AB0F",
          iconColor: "#1594FF",
          height: 70,
          iconUrl: require('../../../assets/images/wharf/设施.png'),
          childrenUrl:{
            '气象站':require('../../../assets/images/wharf/气象站.png'),
            '停车场':require('../../../assets/images/wharf/停车场.png'),
            '办公楼':require('../../../assets/images/wharf/办公楼.png'),
            '岸基潮位站':require('../../../assets/images/wharf/岸基潮位站.png'),
          }
        },
        4: {
          name: '助航设施',
          iconColor: "#1594FF",
          height: 100,
          iconUrl: require('../../../assets/images/wharf/设施.png'),
          childrenUrl:{
            '岸标C43':require('../../../assets/images/wharf/岸标C43.png'),
            '浮标A4':require('../../../assets/images/wharf/浮标A4.png'),
          }
        }
      },

      isPopExtendShow: false
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  destroyed() {
    mapApp.wharfMapActions.closeWharfPop()
    this.isPopExtendShow = false
  },
  // 组件方法
  methods: {
    popExtend(extend) {
      // console.log(this.popData)
      if (extend) {
        this.isPopExtendShow = !this.isPopExtendShow
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pop_container {
  position: absolute;
  // pointer-events: none;
  .pop_img {
    z-index: 10;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3ab0f;
    cursor: pointer;
    opacity: 0;
    animation: roll-in-blurred-left 0.5s 0.3s forwards;
    img {
      width: 70%;
      height: 70%;
    }
  }
  .pop_line {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100px;
    background-image: linear-gradient(to bottom, #f3ab0f, rgba(0, 0, 0, 0));
    animation: scale-in-bl 0.5s;
  }
  .pop_txt {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 25px;
    height: 25px;
    line-height: 25px;
    white-space: nowrap;
    padding: 0 10px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    // font-weight: bold;
    color: #fff;
    background-color: #09161c;
    opacity: 0;
    animation: shutter-in-right 0.3s 0.3s forwards;
    font-family: YouSheBiaoTiHei;
  }
  .pop_extend {
    position: absolute;
    top: 0;
    left: 25px;
    color: #fff;
    animation: shutter-in-top 0.3s 0.3s forwards;
    opacity: 0;
    font-family: SiYuan;
    .pop_title {
      height: 25px;
      line-height: 25px;
      font-size: 12px;
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      background-color: #09161c;
      padding: 0 10px;
      .message {
        display: flex;
        margin: 0 15px 0 15px;
        div {
          padding-right: 5px;
        }
        span {
          color: #39a0f7;
        }
      }
      i {
        position: absolute;
        right: 5px;
        line-height: 25px;
        cursor: pointer;
      }
    }
    .pop_detail {
      background-color: #000;
      white-space: nowrap;
      .video-player {
        width: 260px;
        height: 150px;
        margin: 5px 5px;
      }
      .fullVideo {
        position: absolute;
        bottom: 4px;
        right: 8px;
        padding: 1px 3px;
        font-size: 12px;
        background-color: rgb(145, 145, 145);
        cursor: pointer;
      }
      .inside {
        padding: 5px 10px;
        font-size: 12px;
        div {
          padding: 5px 0;
        }
        span {
          margin: 0px 5px;
          color: #39a0f7;
        }
      }
    }
  }
}

@keyframes scale-in-bl {
  0% {
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes roll-in-blurred-left {
  0% {
    transform: translateX(-20px) rotate(-180deg);
    filter: blur(20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotate(0deg);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes shutter-in-right {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes shutter-in-top {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}
</style>
